import React from "react";
import { Container } from "./style";
import { useSearchParams } from "react-router-dom";
import ChangeForm from "./components/ChangeForm";
import ForgetForm from "./components/ForgetForm";

export default function ResetPwd() {
  const [params] = useSearchParams();
  const type = params.getAll("type")[0];
  return (
    <Container>
      <div className="header">
        <div className="header-logo">
          <span className="main">UVITA</span>
          <span className="sub">PRO</span>
        </div>
        <div></div>
      </div>
      <div className="form">
        <div className="form-box">
          {type === "change" ? <ChangeForm /> : <ForgetForm />}
        </div>
      </div>
    </Container>
  );
}
