import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  Select,
  Tag,
  Popover,
  Input,
  Modal,
  message,
} from "antd";
import { MoreOutlined, SearchOutlined } from "@ant-design/icons";
import { Container } from "./style";
import { gql, useLazyQuery } from "@apollo/client";
const { confirm } = Modal;

const LIST_PRACTICES = gql`
  query listPractices {
    practices {
      _id
      about
      isPremium
      description
      hint
      imageUrl
      link
      name
      rate
      hasAccess
      isBookmarked
      expertise {
        _id
        title
        backgroundColor
        linearColor
        backgroundUrl
        description
      }
      createdBy {
        _id
        name
        email
        belief
        about
        experience
      }
    }
  }
`;

export default function ContentManager() {
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [list, setList] = useState();
  const [getPractices, { data }] = useLazyQuery(LIST_PRACTICES);
  useEffect(() => {
    getPractices();
    if (data && data.practices) {
      setList(data.practices);
    }
  }, [data, getPractices]);

  const statusList = [
    {
      value: "Status: All",
      label: "Status: All",
    },
    {
      value: "Status: Published",
      label: "Status: Published",
    },
    {
      value: "Status: Draft",
      label: "Status: Draft",
    },
    {
      value: "Status: Hide",
      label: "Status: Hide",
    },
  ];
  const columns = [
    {
      title: "Practice Name",
      dataIndex: "name",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => {
        switch (status) {
          case "Published":
            return <Tag color="success">{status}</Tag>;
          default:
            return <Tag>{status}</Tag>;
        }
      },
    },
    {
      title: "Completed",
      dataIndex: "completed",
    },
    {
      title: "Views",
      dataIndex: "views",
    },
    {
      title: "Publish Date",
      dataIndex: "publishDate",
      sorter: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 80,
      render: (action, row) => {
        return (
          <Popover
            content={
              <ul>
                <li>
                  <Button
                    type="text"
                    block
                    className="text-left"
                    onClick={() => onEdit(row)}
                  >
                    Edit
                  </Button>
                </li>
                <li>
                  {row.status !== "Hide" && (
                    <Button
                      type="text"
                      block
                      className="text-left"
                      onClick={() => onHide(row)}
                    >
                      Hide
                    </Button>
                  )}
                </li>
                <li>
                  {row.status === "Hide" && (
                    <Button
                      type="text"
                      block
                      className="text-left"
                      onClick={() => onUnhide(row)}
                    >
                      Unhide
                    </Button>
                  )}
                </li>
                <li>
                  <Button
                    type="text"
                    block
                    className="text-left"
                    onClick={() => onDelete(row)}
                  >
                    Delete
                  </Button>
                </li>
              </ul>
            }
            trigger="click"
            placement="right"
          >
            <Button
              type="text"
              icon={<MoreOutlined style={{ fontSize: "16px" }} />}
            ></Button>
          </Popover>
        );
      },
    },
  ];

  const onStatusChange = (val) => {
    console.log(val);
  };

  useEffect(() => {
    getPractices();
  }, [JSON.stringify(tableParams)]);

  const onTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setList([]);
    }
  };

  // Actions
  const onEdit = () => {};
  const onHide = (data) => {
    message.success(`Quote "${data.name} is hidden from the app!"`);
  };
  const onUnhide = (data) => {
    message.success(
      `Quote "${data.name} is unhidden adn available in the app!"`
    );
  };
  const onDelete = (data) => {
    confirm({
      title: `Delete the quote "${data.name}"?`,
      content:
        "Deleting this quote will permanently remove it from your account. Are you sure to delete this quote",
      okText: "Yes, delete this quote",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        console.log("OK");
        message.success(`Quote "${data.name} is deleted successfully!"`);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  return (
    <Container>
      <div className="flex-between">
        <Input
          size="large"
          placeholder="Search"
          bordered={false}
          prefix={
            <SearchOutlined style={{ fontSize: "20px", marginRight: "10px" }} />
          }
          className="table-search"
        />
        <div className="table-filter">
          <Select
            defaultValue="Status: All"
            style={{ width: 150 }}
            onChange={onStatusChange}
            options={statusList}
          />
        </div>
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record.key}
        dataSource={list}
        pagination={tableParams.pagination}
        loading={loading}
        onChange={onTableChange}
      />
    </Container>
  );
}
