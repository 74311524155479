import "./App.css";
import "./theme/AntOverrides.css";
import React, { useEffect, useMemo, useState } from "react";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import strings from "./utils/strings";
import { mainTheme } from "./theme/Colors";
import Context from "./contexts/LocalSettings";
import { useRoutes } from "react-router-dom";
import { routes } from "./router";
import { ConfigProvider } from "antd";
import AntColors from "./theme/AntColors";
import { useSelector } from "react-redux";

function App() {
  const { accessToken } = useSelector((state) => state.userInfo.loginInfo);
  const [state, setStates] = useState({
    language: "",
    theme: "",
  });
  const setState = (object) =>
    setStates((previousState) => ({ ...previousState, ...object }));

  useEffect(() => {
    strings.setLanguage(
      localStorage.language ||
        ([localStorage.language] = strings.getAvailableLanguages())
    );
    setState({
      language: localStorage.language,
      theme: localStorage.theme || (localStorage.theme = "main"),
    });
  }, []);

  const GlobalStyles = createGlobalStyle`
    html{
      scroll-behavior: smooth
    }
    body {
      align-items: center;
      background-color: ${({ theme }) => theme.body};
      color: ${({ theme }) => theme.text};
      width: 100%;
      transition: all 0.25s linear;
      padding-top: 56px;
      padding-left: 240px;
      ::-webkit-scrollbar {
        width: 10px;
      }
      ::-webkit-scrollbar-track {
        background-color: #e4e4e48f;
      }
      ::-webkit-scrollbar-thumb {
        border-radius: 100px;
        border: 2px solid transparent;
        background-clip: content-box;
        background-color: ${({ theme }) => theme.body};
      }
    }

    @media (min-width:1600px){
      body{
        max-width: 1600px;
        width: 1600px;
        margin: 0 auto;
      }
    }
    `;
  const globalState = useMemo(
    () => ({ globalState: state, setGlobalState: setState }),
    [state]
  );

  const routesJs = useRoutes(routes(!!accessToken));

  return (
    <Context.Provider value={globalState}>
      <ConfigProvider theme={AntColors}>
        <GlobalStyles theme={state.theme === "main" ? mainTheme : mainTheme} />
        <ThemeProvider theme={state.theme === "main" ? mainTheme : mainTheme}>
          <main className="globalClass" dir={strings.language.direction}>
            {routesJs}
          </main>
        </ThemeProvider>
      </ConfigProvider>
    </Context.Provider>
  );
}

export default App;
