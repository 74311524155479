import React, { useState } from "react";
import { Button, Form, Input, Result } from "antd";

export default function ForgetForm() {
  const [isDone, setIsDone] = useState(false);
  const onSubmit = (values) => {
    console.log("Success:", values);
    setIsDone(true);
  };
  const onSubmitFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div>
      {!isDone ? (
        <div>
          <div className="form-title">Forget Password</div>
          <Form
            name="forgetForm"
            layout="vertical"
            size="large"
            onFinish={onSubmit}
            onFinishFailed={onSubmitFailed}
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                  type: "email",
                },
              ]}
            >
              <Input placeholder="Input your email" />
            </Form.Item>
            <br />
            <Form.Item wrapperCol={{ span: 12 }}>
              <Button type="primary" block htmlType="submit">
                Email me a recovery link
              </Button>
            </Form.Item>
          </Form>
        </div>
      ) : (
        <Result
          status="success"
          title="Recover Password"
          subTitle="An email has been sent. Please click the link when you get it."
        />
      )}
    </div>
  );
}
