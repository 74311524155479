import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Table,
  Select,
  Tag,
  Popover,
  Input,
  Modal,
  message,
} from "antd";
import { useNavigate } from "react-router-dom";
import { MoreOutlined, SearchOutlined } from "@ant-design/icons";
import { Container } from "./style";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
const { confirm } = Modal;

const LIST_PROMPTS = gql`
  query ListPrompts($filters: ListPromptInput, $args: PaginationArgs) {
    listPrompts(filters: $filters, args: $args) {
      count
      limit
      offset
      total
      prompts {
        _id
        content
        date
        authorName
        isBookmarked
        completesCount
        viewsCount
        coach {
          _id
          name
          email
          belief
          about
          experience
        }
      }
    }
  }
`;
const UPDATE_PROMPT = gql`
  mutation updatePrompt($updatePromptInput: UpdatePromptInput!) {
    updatePrompt(payload: $updatePromptInput) {
      _id
    }
  }
`;

const DELETE_PROMPT = gql`
  mutation deletePrompt($_id: String!) {
    deletePrompt(_id: $_id) {
      _id
    }
  }
`;

export default function PromptManager() {
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.userInfo.userInfo);
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState({});
  const [total, setTotal] = useState(0);
  const [list, setList] = useState([]);
  const initVariables = {
    filters: {
      createdBy: userInfo.coach._id,
    },
    args: {
      limit: 10,
      offset: 0,
    },
  };

  const [getPrompts, { data, refetch, called }] = useLazyQuery(LIST_PROMPTS, {
    variables: initVariables,
  });
  const [updatePrompt] = useMutation(UPDATE_PROMPT);
  const [deletePrompt] = useMutation(DELETE_PROMPT);

  const statusList = [
    {
      label: "Status: All",
      value: "",
    },
    {
      label: "Status: Published",
      value: "PUBLISHED",
    },
    {
      label: "Status: Draft",
      value: "DRAFTED",
    },
    {
      label: "Status: Hide",
      value: "HIDDEN",
    },
  ];

  const columns = [
    {
      title: "Prompts",
      dataIndex: "content",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => {
        switch (status) {
          case "PUBLISHED":
            return <Tag color="success">{status}</Tag>;
          default:
            return <Tag>{status}</Tag>;
        }
      },
    },
    {
      title: "Completed",
      dataIndex: "completesCount",
    },
    {
      title: "Views",
      dataIndex: "viewsCount",
    },
    {
      title: "Publish Date",
      dataIndex: "date",
      // sorter: true,
      render: (text) => {
        const date = new Date(text);
        const options = {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
        const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
          date
        );
        return formattedDate;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 80,
      render: (action, row) => {
        return (
          <Popover
            placement="right"
            content={
              <ul>
                <li>
                  <Button
                    type="text"
                    block
                    className="text-left"
                    onClick={() => onEdit(row)}
                  >
                    Edit
                  </Button>
                </li>
                <li>
                  {row.status !== "Hide" && (
                    <Button
                      type="text"
                      block
                      className="text-left"
                      data-row={JSON.stringify(row)}
                      onClick={onHide}
                    >
                      Hide
                    </Button>
                  )}
                </li>
                <li>
                  {row.status === "Hide" && (
                    <Button
                      type="text"
                      block
                      className="text-left"
                      data-row={JSON.stringify(row)}
                      onClick={onUnhide}
                    >
                      Unhide
                    </Button>
                  )}
                </li>
                <li>
                  <Button
                    type="text"
                    block
                    className="text-left"
                    data-row={JSON.stringify(row)}
                    onClick={onDelete}
                  >
                    Delete
                  </Button>
                </li>
              </ul>
            }
          >
            <Button
              type="text"
              icon={<MoreOutlined style={{ fontSize: "16px" }} />}
            ></Button>
          </Popover>
        );
      },
    },
  ];

  useEffect(() => {
    setTableParams(initVariables);
  }, []);

  useEffect(() => {
    getData();
  }, [JSON.stringify(tableParams)]);

  useEffect(() => {
    if (data?.listPrompts?.prompts) {
      setList(data.listPrompts.prompts);
    } else {
      setList([]);
    }
    setTotal(data?.listPrompts?.total);
  }, [data]);

  const getData = async () => {
    if (!tableParams.args || !tableParams.args.limit) {
      return;
    }
    setLoading(true);
    // if (called) {
    await refetch(tableParams);
    // } else {
    //   await getPrompts(tableParams);
    // }
    setLoading(false);
  };
  const onSearch = (val) => {
    let param = {
      filters: Object.assign({}, tableParams.filters, {
        name: val.target.value,
      }),
      args: tableParams.args,
    };
    if (!val.target.value) {
      delete param.filters.name;
    }
    setTableParams(param);
  };
  const onStatusChange = (val) => {
    let param = { filters: tableParams.filters, args: tableParams.args };
    if (val) {
      param.filters.status = val;
    } else {
      delete param.filters.status;
    }
    setTableParams(param);
  };

  const onTableChange = (pagination, filters, sorter) => {
    console.log(pagination);
    setTableParams({
      filters: tableParams.filters,
      args: {
        limit: pagination.pageSize,
        offset: pagination.pageSize * (pagination.current - 1),
      },
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.args?.limit) {
      setList([]);
    }
  };

  // Actions
  const onEdit = (data) => {
    navigate("/CreatePrompt?id=" + data._id);
  };
  const onHide = (e) => {
    const rowData = JSON.parse(e.target?.parentNode?.dataset?.row);
    confirm({
      title: `Hide the prompt "${rowData.name}"?`,
      content: "Are you sure to hide this prompt",
      okText: "Yes, hide this prompt",
      okType: "danger",
      cancelText: "Cancel",
      async onOk() {
        const param = {
          _id: rowData._id,
          status: "HIDDEN",
        };
        await updatePrompt({
          variables: {
            updatePromptInput: param,
          },
        });
        message.success(`Prompt "${rowData.name}" is hidden from the app!`);
        getData();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const onUnhide = (e) => {
    const rowData = JSON.parse(e.target?.parentNode?.dataset?.row);
    confirm({
      title: `Unhide the prompt "${rowData.name}"?`,
      content: "Are you sure to unhide this prompt",
      okText: "Yes, unhide this prompt",
      okType: "danger",
      cancelText: "Cancel",
      async onOk() {
        const param = {
          _id: rowData._id,
          status: "PUBLISHED",
        };
        await updatePrompt({
          variables: {
            updatePromptInput: param,
          },
        });
        message.success(
          `Prompt "${rowData.name}" is unhidden adn available in the app!`
        );
        getData();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const onDelete = (e) => {
    const rowData = JSON.parse(e.target?.parentNode?.dataset?.row);
    confirm({
      title: `Delete the prompt "${rowData.name}"?`,
      content:
        "Deleting this prompt will permanently remove it from your account. Are you sure to delete this prompt?",
      okText: "Yes, delete this prompt",
      okType: "danger",
      cancelText: "Cancel",
      async onOk() {
        await deletePrompt({
          variables: {
            _id: rowData._id,
          },
        });
        message.success(`Prompt "${rowData.name}" is deleted successfully!`);
        getData();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <Container>
      <div className="flex-between">
        <Input
          size="large"
          placeholder="Search"
          bordered={false}
          prefix={
            <SearchOutlined style={{ fontSize: "20px", marginRight: "10px" }} />
          }
          className="table-search"
          onPressEnter={onSearch}
        />
        <div className="table-filter">
          <Select
            defaultValue="Status: All"
            style={{ width: 150 }}
            onChange={onStatusChange}
            options={statusList}
          />
        </div>
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record._id}
        dataSource={list}
        pagination={{ total }}
        loading={loading}
        onChange={onTableChange}
      />
    </Container>
  );
}
