import React, { useEffect, useState } from "react";
import { Container } from "./style";
import { Button, Form, Input, Modal, Avatar, Tag } from "antd";
import { LeftOutlined, TagOutlined, EditOutlined } from "@ant-design/icons";
import ReactRouterPrompt from "react-router-prompt";
import MockPhone from "../../../components/MockPhone/index";
import UserAvatar from "../../../assets/User.png";
const { TextArea } = Input;

export default function CreateQuote() {
  const [loading, setLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const rowData = {};

  // Form
  const [form] = Form.useForm();
  const quoteValue = Form.useWatch("quote", form);

  useEffect(() => {
    if (quoteValue && quoteValue !== rowData.quote) {
      setIsChanged(true);
    }
  }, [quoteValue]);

  useEffect(() => {
    if (loading) {
      setIsChanged(false);
    }
  }, [loading]);

  const onFinish = (values) => {
    console.log("Success:", values);
    setLoading(true);
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setLoading(false);
  };

  const onDraft = () => {
    const values = form.getFieldsValue();
  };

  const onBack = () => {};

  return (
    <Container forceRender>
      <ReactRouterPrompt when={isChanged}>
        {({ isActive, onConfirm, onCancel }) =>
          isActive && (
            <Modal
              title="Unsaved changes"
              open
              footer={[
                <div key="flex-between" className="flex-between">
                  <Button
                    key="discard"
                    danger
                    loading={loading}
                    onClick={() => {
                      onConfirm();
                    }}
                  >
                    Discard Changes
                  </Button>
                  <div key="div">
                    <Button key="back" onClick={onCancel}>
                      Cancel
                    </Button>
                    <Button
                      key="save"
                      type="primary"
                      loading={loading}
                      onClick={() => {
                        onDraft();
                        onConfirm();
                      }}
                    >
                      Save Draft
                    </Button>
                  </div>
                </div>,
              ]}
            >
              <p>
                You are about to leave this page with unsaved changes. Would you
                like to save these changes before leaving?
              </p>
            </Modal>
          )
        }
      </ReactRouterPrompt>
      <div className="page-form">
        <div className="left-side">
          <Button
            className="btn-back"
            icon={<LeftOutlined />}
            type="text"
            onClick={onBack}
          >
            Content Manager
          </Button>

          <div className="page-title">Create a new Quote</div>
          <Form
            form={form}
            name="quoteForm"
            layout="vertical"
            size="large"
            className="full-width"
            initialValues={rowData}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Enter your quote"
              name="quote"
              rules={[{ required: true, message: "Please input quote!" }]}
            >
              <TextArea
                showCount
                maxLength={500}
                placeholder="Empty"
                style={{ resize: "none", height: 250 }}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" block htmlType="submit" loading={loading}>
                Publish
              </Button>
              <Button block className="mt-16" onClick={onDraft}>
                Save as draft
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div className="right-side">
          <MockPhone showTabbar>
            <div className="info-box gray-all">
              <div className="flex-between mb-10">
                <div className="c-gray">By Coach Name</div>
                <TagOutlined />
              </div>
              <div className="info-content">
                "Is there a simple way to display a color bitmap in grayscale
                with just HTML/CSS?"
              </div>
              <div className="text-right">
                <Button type="primary" icon={<EditOutlined />}>
                  Write
                </Button>
              </div>
            </div>
            <div className="flex-between mb-10">
              <div>Today's Quotes/Insights</div>
              <Button type="text">
                <b>More</b>
              </Button>
            </div>
            <div className="info-box">
              <div className="flex-between mb-10">
                <div>
                  <Avatar src={UserAvatar} className="mr-10" />
                  Coach Name
                  <span className="c-gray ml-10">FEB 24, 2023</span>
                </div>
                <TagOutlined />
              </div>
              <div className="info-content">{quoteValue || "Empty"}</div>
            </div>
            <div className="flex-between mb-10 gray-all">
              <div>Practices Recommended for you</div>
              <Button type="text">
                <b>More</b>
              </Button>
            </div>
            <div className="flex-between mb-10 gray-all">
              <img
                className="practice-image"
                src="https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png"
                alt="image"
              />
              <div className="fs-13">
                <div className="bold mb-10">
                  <Tag color="#108ee9">Sleep</Tag> Premium
                </div>
                <h4 className="mb-10">Quisque auctor nulia con</h4>
                <div>
                  Quisque commodo david beckham ronaldinho leo messi luka toni
                  totti canavaro
                </div>
                <div className="flex-between mt-10">
                  <div className="c-gray fs-12">6 min</div>
                  <div className="c-gray fs-12">32 completed</div>
                </div>
              </div>
            </div>
            <div className="flex-between gray-all">
              <img
                className="practice-image"
                src="https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png"
                alt="image"
              />
              <div className="fs-13">
                <div className="bold mb-10">
                  <Tag color="#f50">Depresion</Tag> Premium
                </div>
                <h4 className="mb-10">Quisque auctor nulia con</h4>
                <div>
                  Quisque commodo david beckham ronaldinho leo messi luka toni
                  totti canavaro
                </div>
                <div className="flex-between mt-10">
                  <div className="c-gray fs-12">6 min</div>
                  <div className="c-gray fs-12">32 completed</div>
                </div>
              </div>
            </div>
          </MockPhone>
        </div>
      </div>
    </Container>
  );
}
