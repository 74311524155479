import React from "react";
import { Container } from "./style";
import { Button, Dropdown, Avatar } from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setLoginInfo } from "../../store/features/UserInfo";
import UserAvatar from "../../assets/User.png";

export default function Heading() {
  const userInfo = useSelector((state) => state.userInfo.userInfo);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onChangePwd = () => {
    navigate("/ResetPwd?type=change");
  };
  const onLogout = () => {
    dispatch(setLoginInfo(""));
    navigate("/Login");
  };

  const items = [
    // {
    //   key: "1",
    //   label: <a onClick={onChangePwd}>Change password</a>,
    // },
    {
      key: "2",
      label: <a onClick={onLogout}>Log out</a>,
    },
  ];

  return (
    <Container>
      <div className="header-logo">
        <span className="main">UVITA</span>
        <span className="sub">PRO</span>
      </div>

      <Dropdown
        menu={{ items }}
        overlayStyle={{ minWidth: "150px" }}
        placement="bottom"
      >
        <Button className="user-name" type="text">
          <Avatar
            src={
              (userInfo.user && userInfo.user.profile_image) ||
              (userInfo.coach && userInfo.coach.profile_image) ||
              UserAvatar
            }
            className="mr-10"
          />
          {userInfo.user && userInfo.user.name}
        </Button>
      </Dropdown>
    </Container>
  );
}
