import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container } from "./style";
import MockPhone from "../../../components/MockPhone/index";
import { Button, Tag } from "antd";
import { useSelector } from "react-redux";
import UserDefaut from "../../../assets/User.png";
import { gql, useLazyQuery } from "@apollo/client";

const LIST_EXPERTISES = gql`
  query listExpertises {
    expertises {
      _id
      title
      backgroundColor
      linearColor
      backgroundUrl
      description
    }
  }
`;

export default function ProfileBuilder() {
  const userInfo = useSelector((state) => state.userInfo.userInfo);
  const [expertiseList, setExpertiseList] = useState([]);
  const [getExpertises, { data }] = useLazyQuery(LIST_EXPERTISES);
  useEffect(() => {
    getExpertises();
    if (data) {
      const list = data.expertises.map((v) => {
        return {
          value: v._id,
          label: v.title,
          backgroundColor: v.backgroundColor,
          linearColor: v.linearColor,
        };
      });
      const selected = list.filter((v) =>
        userInfo.coach?.expertise?.includes(v.value)
      );
      setExpertiseList(selected);
    }
  }, [data, getExpertises]);

  return (
    <Container>
      <div className="profileBuilderLeftSide">
        <div className="page-title">Profile Builder</div>
        <div className="mockupHintParagraph">
          Please see your current coach profile mockup on the right.
        </div>
        <Link to="/ProfileBuilder/Edit">
          <Button size="large" type="primary">
            Edit my profile
          </Button>
        </Link>
      </div>
      <div className="profileBuilderRightSide">
        <MockPhone title={userInfo.coach?.name || "Coach name"} showTabbar>
          <div className="mock-top">
            <img
              src={userInfo.coach?.profile_image || UserDefaut}
              alt="profile_image"
              className="mock-avatar"
            />
            <div className="mock-headline">
              {userInfo.coach?.belief || "Headline"}
            </div>
          </div>
          <div className="mock-form">
            <div className="mock-form-item">
              <div className="mock-item-title">Expertise</div>
              <div
                className={`mock-item-value ${
                  !userInfo.coach?.expertise && "c-gray"
                }`}
              >
                {(expertiseList &&
                  expertiseList.map((v, i) => {
                    return (
                      <Tag key={i} color={v.backgroundColor} className="mb-10">
                        {v.label}
                      </Tag>
                    );
                  })) ||
                  "Empty"}
              </div>
            </div>
            <div className="mock-form-item">
              <div className="mock-item-title">About</div>
              <div
                className={`mock-item-value ${
                  !userInfo.coach?.about && "c-gray"
                }`}
              >
                {userInfo.coach?.about || "Empty"}
              </div>
            </div>
            <div className="mock-form-item">
              <div className="mock-item-title">Experience</div>
              <div
                className={`mock-item-value ${
                  !userInfo.coach?.experience && "c-gray"
                }`}
              >
                {userInfo.coach?.experience || "Empty"}
              </div>
            </div>
            <div className="mock-form-item">
              <div className="mock-item-title">
                Practices
                <div className="right c-primary fs-13">More</div>
              </div>
              <div className="mock-item-value c-gray">Empty</div>
            </div>
          </div>
        </MockPhone>
      </div>
    </Container>
  );
}
