import React from "react";
import { Icon } from "@iconify/react";
import {
  useLocation,
  useResolvedPath,
  useParams,
  useNavigate,
  Link,
} from "react-router-dom";

import { Container } from "./style";

export default function Navigation() {
  const { pathname } = useLocation();
  const navItems = [
    {
      id: "ContentManager",
      icon: <Icon icon="akar-icons:file" width="16.5" height="16.5" />,
      text: "Content Manager",
    },
    {
      id: "ProfileBuilder",
      icon: <Icon icon="carbon:user-avatar" width="16.5" height="16.5" />,
      text: "Profile Builder",
    },
    // {
    //   id: "Communication",
    //   icon: <Icon icon="bx:message-detail" width="15" height="15" />,
    //   text: "Communication",
    // },
    // {
    //   id: "Notification",
    //   icon: <Icon icon="akar-icons:bell" width="16.5" height="16.5" />,
    //   text: "Notification",
    // },
  ];

  const settingItems = [
    {
      id: "Settings",
      icon: <Icon icon="clarity:settings-line" width="16.5" height="16.5" />,
      text: "Settings",
    },
    {
      id: "Info",
      icon: <Icon icon="akar-icons:info" width="16.5" height="16.5" />,
      text: "Info",
    },
  ];

  return (
    <Container>
      <div className="navbar">
        {navItems.map((navItem, key) => (
          <Link
            to={`/${navItem.id}`}
            className={`navItem ${
              pathname.includes(navItem.id) && "selectedItem"
            }`}
            key={key}
          >
            {navItem.icon}
            {navItem.text}
          </Link>
        ))}
      </div>
      {/* <div className="settings">
        {settingItems.map((settingItem, key) => (
          <div
            className={`settingItem ${
              pathname.includes(settingItem.id) && "selectedItem"
            }`}
            key={key}
          >
            {settingItem.icon}
            {settingItem.text}
          </div>
        ))}
      </div> */}
    </Container>
  );
}
