import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setSummaryData,
  setCurrentPage,
} from "../../../../store/features/CreatePractice";
import { Container } from "../style";
import { Button, Form, Input, Tag, Avatar, Tabs, Image, Checkbox } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import PutUpload from "../../../../components/PutUpload/index";
import SelectTags from "../../../../components/SelectTags/index";
import MockPhone from "../../../../components/MockPhone/index";
import UserAvatar from "../../../../assets/User.png";
import { gql, useLazyQuery } from "@apollo/client";

const LIST_EXPERTISES = gql`
  query listExpertises {
    expertises {
      _id
      title
      backgroundColor
      linearColor
      backgroundUrl
      description
    }
  }
`;

const defImg =
  "https://user-images.githubusercontent.com/43302778/106805462-7a908400-6645-11eb-958f-cd72b74a17b3.jpg";

const { TextArea } = Input;

export default function SummaryForm({ getIsChanged, onBack, onSubmit }) {
  const dispatch = useDispatch();
  const summaryData = useSelector((state) => state.practice.summaryData);
  const userInfo = useSelector((state) => state.userInfo.userInfo);

  const [loading, setLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [expertiseList, setExpertiseList] = useState([]);
  const [checked, setChecked] = useState(true);
  const [getExpertises, { data }] = useLazyQuery(LIST_EXPERTISES);

  useEffect(() => {
    if (form && summaryData.name) {
      form.setFieldsValue(summaryData);
      setChecked(summaryData.isPremium);
    } else {
      form.resetFields();
    }
  }, [summaryData]);
  useEffect(() => {
    setImageUrl(summaryData.imageUrl);
  }, [summaryData.imageUrl]);

  useEffect(() => {
    getExpertises();
    if (data) {
      const list = data.expertises.map((v) => {
        return {
          value: v._id,
          label: v.title,
          backgroundColor: v.backgroundColor,
          linearColor: v.linearColor,
        };
      });
      setExpertiseList(list);
    }
  }, [data, getExpertises]);
  const onSelectTags = (val) => {
    setSelectedTags(val);
  };

  useEffect(() => {
    getIsChanged(isChanged);
  }, [isChanged, getIsChanged]);

  // Upload
  const onUpload = (val) => {
    setImageUrl(val);
  };
  const onCheckChange = (e) => {
    setChecked(e.target.checked);
  };
  // Form
  const [form] = Form.useForm();
  const nameValue = Form.useWatch("name", form);
  const aboutValue = Form.useWatch("about", form);

  const onFormChange = () => {
    const name = form.getFieldValue("name");
    setIsChanged(!!name);
  };

  const onFinish = (values) => {
    console.log("Success:", values);
    setLoading(true);
    dispatch(
      setSummaryData(
        Object.assign({}, values, {
          _id: summaryData._id,
          wrap_up: summaryData.wrap_up,
          imageUrl,
          expertise: values.expertise.map((v) => {
            return v.value ? v.value : v;
          }),
          isPremium: checked,
          description: values.about,
        })
      )
    );
    dispatch(setCurrentPage(1));
    setLoading(false);
    onSubmit();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setLoading(false);
  };

  return (
    <Container>
      <div className="page-form">
        <div className="left-side">
          <div className="page-title">Summary</div>
          <Form
            form={form}
            name="summaryForm"
            layout="vertical"
            size="large"
            className="full-width"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            onValuesChange={onFormChange}
          >
            <Form.Item
              label="Practice name"
              name="name"
              rules={[
                { required: true, message: "Please input your practice name" },
              ]}
            >
              <Input placeholder="Input your practice name" />
            </Form.Item>
            <Form.Item
              label="Upload cover image"
              name="imageUrl"
              rules={[{ required: true, message: "Please upload cover image" }]}
            >
              <PutUpload
                fileName={`${
                  userInfo?.coach?._id
                }_${+new Date()}_practice_cover.png`}
                onChange={onUpload}
              ></PutUpload>
            </Form.Item>
            <Form.Item
              label="Categories (Please select max 3 items)"
              name="expertise"
              rules={[
                { required: true, message: "Please select your categories" },
              ]}
            >
              <SelectTags
                initialValues={summaryData && summaryData.expertise}
                options={expertiseList}
                onChange={onSelectTags}
              />
            </Form.Item>
            <Form.Item label="Is premium?" name="isPremium">
              <Checkbox checked={checked} onChange={onCheckChange}>
                {checked ? "Premium content" : "Free and public"}
              </Checkbox>
            </Form.Item>
            <Form.Item label="About" name="about">
              <TextArea
                showCount
                maxLength={200}
                placeholder="Input about"
                style={{ resize: "none", height: "200px" }}
              />
            </Form.Item>
            <br />
            <Form.Item>
              <Button type="primary" block htmlType="submit" loading={loading}>
                Next
              </Button>
              <Button block className="mt-16" onClick={onBack}>
                Back
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div className="right-side">
          <MockPhone title={"Practice"} showTabbar>
            <h3 className="mt-10 mb-10">{nameValue || "Practice name"}</h3>
            {(selectedTags &&
              selectedTags.map((v, i) => {
                return (
                  <Tag key={i} color={v?.backgroundColor} className="mb-10">
                    {v?.label}
                  </Tag>
                );
              })) || <Tag>Empty</Tag>}
            <div className="author-line flex-between">
              <Avatar
                src={userInfo?.coach?.profile_image || UserAvatar}
                className="mr-10"
              />
              <div className="author-info">
                <div className="author-name">{userInfo?.coach?.name}</div>
                <div className="author-desc">{userInfo?.coach?.belief}</div>
              </div>
              <Button type="primary" size="small" ghost icon={<PlusOutlined />}>
                Follow
              </Button>
            </div>
            <div className="cover-image">
              <Image
                width={"100%"}
                src={imageUrl || defImg}
                fallback={defImg}
                preview={false}
              />
            </div>
            <Tabs
              className="full-tabs"
              defaultActiveKey="1"
              items={[
                {
                  key: "1",
                  label: "About",
                  children: [
                    <pre key="1-pre" className="edit-body">
                      {aboutValue || "Empty"}
                    </pre>,
                  ],
                },
                { key: "2", label: "Practices", children: "Empty" },
                { key: "3", label: "Author", children: "Empty" },
              ]}
            />
          </MockPhone>
        </div>
      </div>
    </Container>
  );
}
