import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  setCurrentPage,
  setSummaryData,
} from "../../../../store/features/CreatePractice";
import { Container } from "../style";
import { Button, Form, Input, Progress, message } from "antd";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import MockPhone from "../../../../components/MockPhone/index";
import iconCongrats from "../../../../assets/icon_congrats.png";
import { gql, useMutation } from "@apollo/client";
const { TextArea } = Input;

const CREATE_PRACTICE = gql`
  mutation createPractice($createPracticeInput: CreatePracticeInput!) {
    createPractice(payload: $createPracticeInput) {
      _id
    }
  }
`;
const UPDATE_PRACTICE = gql`
  mutation UpdatePractice($payload: UpdatePracticeInput!) {
    updatePractice(payload: $payload) {
      _id
      about
      description
      hint
      imageUrl
      link
      name
      rate
      isPremium
      publishedAt
    }
  }
`;
const CREATE_MULTIPLE_QUESTIONS = gql`
  mutation createMultipleQuestions($payload: CreateMultipleQuestionsInput!) {
    createMultipleQuestions(payload: $payload) {
      _id
      title
      placeholder
      description
      order
      extraData
      practiceId
    }
  }
`;
const UPDATE_MULTIPLE_QUESTIONS = gql`
  mutation updateMultipleQuestions($payload: UpdateMultipleQuestionsInput!) {
    updateMultipleQuestions(payload: $payload) {
      _id
      title
      placeholder
      description
      order
      extraData
    }
  }
`;

const typeList = {
  desc: "TEXT_ONLY",
  short: "ONE_QUESTION_ONE_INPUT_TO_ANSWER",
  multi: "ONE_QUESTION_MANY_OPTION_TO_SELECT",
};

export default function WrapForm({ onBack, onComplete }) {
  const userInfo = useSelector((state) => state.userInfo.userInfo);
  const summaryData = useSelector((state) => state.practice.summaryData);
  const editData = useSelector((state) => state.practice.editData);
  const editAddData = useSelector((state) => state.practice.editAddData);
  const pages = useSelector((state) => state.practice.pages);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [createPractice] = useMutation(CREATE_PRACTICE);
  const [updatePractice] = useMutation(UPDATE_PRACTICE);
  const [createMultipleQuestions] = useMutation(CREATE_MULTIPLE_QUESTIONS);
  const [updateMultipleQuestions] = useMutation(UPDATE_MULTIPLE_QUESTIONS);
  const [params] = useSearchParams();
  const paramId = params && params.getAll("id") && params.getAll("id")[0];

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (form && summaryData.wrap_up) {
      form.setFieldsValue(summaryData);
    }
  }, [summaryData]);
  // Form
  const [form] = Form.useForm();
  const wrap_up_value = Form.useWatch("wrap_up", form);
  useEffect(() => {
    if (wrap_up_value) {
      dispatch(
        setSummaryData(
          Object.assign({}, summaryData, { wrap_up: wrap_up_value })
        )
      );
    }
  }, [wrap_up_value]);

  const onClickPrev = () => {
    dispatch(setCurrentPage(pages));
    onBack();
  };

  console.log(summaryData, editData, editAddData);
  // param: values, status
  const onSubmit = async (values, status) => {
    setLoading(true);
    let practiceId = "";
    try {
      // Get practice data
      const param = {
        status,
        // active: true,
        createdBy: userInfo.coach._id,
        ...values,
        ...summaryData,
      };
      if (!paramId) {
        // Create practice
        const res = await createPractice({
          variables: {
            createPracticeInput: param,
          },
        });
        practiceId =
          res.data && res.data.createPractice && res.data.createPractice._id;
      } else {
        // Update practice
        delete param.active;
        delete param.createdBy;
        param._id = paramId;
        await updatePractice({
          variables: {
            payload: param,
          },
        });
        practiceId = paramId;
      }
      // Get questions data
      let questions = [];
      questions.push({
        order: 1,
        practiceId,
        extraData: `{"duration":"${editData.duration}"}`,
        title: editData.title,
        description: editData.description,
        type: "TEXT_ONLY",
      });
      // Update without practiceId
      if (paramId) {
        delete questions[0]?.practiceId;
      }
      editAddData.length &&
        editAddData.map((v, i) => {
          let extraData = "{";
          // if (v.subtitle) {
          //   extraData += `"subtitle":"${v.subtitle}"`;
          // }
          if (v?.options?.length > 0 && v?.options[0]) {
            if (extraData.length > 2) extraData += ",";
            extraData += `"options":[`;
            v.options.map((option, i) => {
              extraData += `{"key":"${option}","value":"${option}"}`;
              if (i < v.options.length - 1) {
                extraData += ",";
              }
            });
            extraData += "]";
          }
          extraData += "}";
          let obj = {
            order: parseInt(i) + 2,
            practiceId,
            type: typeList[v.layout],
            title: v.title,
            description: v.description,
            extraData,
          };
          // Update without practiceId
          if (paramId) {
            delete obj.practiceId;
          }
          questions.push(obj);
        });
      if (!paramId) {
        // Create multiple questions
        await createMultipleQuestions({
          variables: {
            payload: {
              questions,
            },
          },
        });
        message.success("Practice has been created!");
      } else {
        // Update multiple questions
        await updateMultipleQuestions({
          variables: {
            payload: {
              questions,
            },
          },
        });
        message.success("Practice has been updated!");
      }
      navigate("/contentManager");
    } finally {
      setLoading(false);
    }
    setSummaryData(
      Object.assign({}, summaryData, {
        wrap_up: wrap_up_value,
      })
    );
    onComplete();
  };

  const onFinish = async (values) => {
    console.log("Success:", values);
    onSubmit(values, "PUBLISHED");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setLoading(false);
  };

  const onDraft = () => {
    const values = form.getFieldsValue();
    onSubmit(values, "DRAFTED");
  };

  return (
    <Container>
      <div className="page-form">
        <div className="left-side">
          <div className="page-title">Wrap Up Practice</div>
          <div className="nav-line flex-around">
            <Button
              icon={<CaretLeftOutlined />}
              type="text"
              onClick={onClickPrev}
            ></Button>
            <div className="text-center">{`Page${pages + 1}`}</div>
            <Button icon={<CaretRightOutlined />} type="text" disabled></Button>
          </div>
          <Form
            form={form}
            name="wrapForm"
            layout="vertical"
            size="large"
            className="full-width"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item label="Body content (Optional)" name="wrap_up">
              <TextArea
                showCount
                maxLength={100}
                placeholder="Input wrap up body content"
                style={{ resize: "none" }}
              />
            </Form.Item>
            <br />
            <Form.Item>
              <Button type="primary" block htmlType="submit" loading={loading}>
                Publish
              </Button>
              <Button
                block
                className="mt-16"
                onClick={onDraft}
                loading={loading}
              >
                Save as draft
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div className="right-side">
          <MockPhone
            title={(summaryData && summaryData.name) || "Practice Name"}
          >
            <Progress
              className="mock-progress"
              percent={100}
              showInfo={false}
              strokeLinecap="square"
              strokeColor={"#547756"}
            />
            <img src={iconCongrats} alt="Congrats" className="mock-congrats" />
            <div className="complete-hint">
              <p>Congrats!</p>
              <p>You just completed the practice.</p>
            </div>

            {wrap_up_value && (
              <pre className="wrap-body">{wrap_up_value || "Body content"}</pre>
            )}
            <Button block type="primary" size="large">
              Complete
            </Button>
          </MockPhone>
        </div>
      </div>
    </Container>
  );
}
