import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import store from "../store";
import { message } from "antd";
const httpLink = createHttpLink({
  uri: `https://dev.uvita-coach.link/graphql`, // dev
  // uri: `https://${
  //   process.env.NODE_ENV === "production" ? "prod" : "dev"
  // }.uvita-coach.link/graphql`,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const storeData = store.getState();
  const token =
    storeData.userInfo.loginInfo && storeData.userInfo.loginInfo.idToken;
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const errorLink = onError(({ networkError, response }) => {
  let errorMsg = "";
  if (!!response && response.errors !== undefined && response.errors.length) {
    errorMsg = !response.errors[0].message
      ? "Server error"
      : response.errors[0].message;
  }
  if (!!networkError) {
    errorMsg = networkError.message;
    if (networkError.result !== undefined) {
      errorMsg =
        networkError.result.success === false
          ? networkError.result.message
          : networkError.result.error;
    }
    if (
      networkError.result &&
      networkError.result.errors &&
      networkError.result.errors.length > 0
    ) {
      errorMsg = networkError.result.errors[0].message
        ? networkError.result.errors[0].message
        : errorMsg;
    }
  }
  if (!!errorMsg) {
    message.error(errorMsg);
  }
  if (errorMsg === "Unauthorized") {
    window.location.href = window.location.origin + "/login";
  }
});

export const client = new ApolloClient({
  link: errorLink.concat(authLink.concat(httpLink)),
  cache: new InMemoryCache(),
});
