import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  setCurrentPage,
  setPages,
  setEditAddData,
} from "../../../../store/features/CreatePractice";
import { Container } from "../style";
import {
  Button,
  Form,
  Input,
  Select,
  Row,
  Col,
  Progress,
  Checkbox,
  Radio,
  Space,
  Modal,
} from "antd";
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  CloseCircleFilled,
} from "@ant-design/icons";
import MockPhone from "../../../../components/MockPhone/index";
const { TextArea } = Input;
const { confirm } = Modal;

export default function EditForm({ onBack, onComplete }) {
  const summaryData = useSelector((state) => state.practice.summaryData);
  const editAddData = useSelector((state) => state.practice.editAddData);
  const currentPage = useSelector((state) => state.practice.currentPage);
  const pages = useSelector((state) => state.practice.pages);
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const paramId = params && params.getAll("id") && params.getAll("id")[0];

  const [loading, setLoading] = useState(false);
  const [layout, setLayout] = useState("desc");
  const [options, setOptions] = useState([""]);
  // const [singleSelect, setSingleSelect] = useState(false);
  const [optionLoading, setOptionLoading] = useState(false);
  // Form
  const [form] = Form.useForm();
  const titleValue = Form.useWatch("title", form);
  // const subtitleValue = Form.useWatch("subtitle", form);
  const descriptionValue = Form.useWatch("description", form);

  // Fill form data
  useEffect(() => {
    const data = editAddData[currentPage - 2];
    if (form && data) {
      let initData = { ...data };
      // if (data?.extraData?.includes("subtitle")) {
      // initData.subtitle = JSON.parse(data.extraData).subtitle;
      // }
      if (data?.extraData?.includes("options")) {
        initData.options = JSON.parse(data.extraData)?.options;
        setOptions(initData.options.map((v) => v.value));
      }
      form.setFieldsValue(initData);
    }
  }, [editAddData]);
  useEffect(() => {
    const data = editAddData[currentPage - 2];
    if (data) {
      form.setFieldsValue(data);
      setLayout(data.layout);
      if (data.layout === "multi") {
        if (data?.options) {
          setOptions(data.options);
        } else if (data?.extraData?.includes("options")) {
          let array = JSON.parse(data.extraData)?.options;
          setOptions(array.map((v) => v.value));
        }
      }
    } else {
      // setSingleSelect(false);
      setOptions([""]);
      setOptionLoading(false);
      setLayout("desc");
      form.resetFields();
    }
  }, [currentPage]);

  const changeLayout = (val) => {
    setLayout(val);
  };

  const getPercent = () => {
    if (currentPage > 9) {
      return 90;
    } else {
      return currentPage * 10;
    }
  };

  // Multiple options
  // const onSingleSelect = (e) => {
  //   setSingleSelect(e.target.checked);
  //   const opts = [options[0]];
  //   setOptions(opts);
  // };
  const onDeleteOption = (i) => {
    // if (singleSelect) {
    //   return;
    // }
    setOptionLoading(true);
    const opts = options.filter((v, index) => index !== i);
    setOptions(opts);
    setTimeout(() => {
      setOptionLoading(false);
    }, 1);
  };
  const onInputOption = (e) => {
    const i = e.target.dataset.index;
    const value = e.target.value;
    const opts = [...options];
    opts[i] = value;
    setOptions(opts);
  };
  const onAddOption = () => {
    const opts = [...options, ""];
    setOptions(opts);
  };

  const onFinish = (values) => {
    console.log("Success:", values);
    setLoading(true);
    onAddPage(values);
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setLoading(false);
  };

  const onClickPrev = () => {
    const values = form.getFieldsValue();
    dispatch(
      setEditAddData({
        type: "add",
        formData: Object.assign({}, values, { layout, options }),
      })
    );
    if (currentPage === 2) {
      onBack();
    } else {
      dispatch(setCurrentPage(currentPage - 1));
    }
  };

  const onClickNext = () => {
    const values = form.getFieldsValue();
    dispatch(
      setEditAddData({
        type: "add",
        formData: Object.assign({}, values, { layout, options }),
      })
    );
    if (currentPage === pages) {
      onComplete();
    } else {
      dispatch(setCurrentPage(currentPage + 1));
    }
  };

  const onAddPage = (values) => {
    dispatch(
      setEditAddData({
        type: "add",
        formData: Object.assign({}, values, { layout, options }),
      })
    );
    dispatch(setCurrentPage(pages + 1));
    dispatch(setPages(pages + 1));
    // init data
    setLayout("desc");
    // setSingleSelect(false);
    setOptions([""]);
    setOptionLoading(false);
    form.resetFields();
  };

  const onDeletePage = () => {
    // Confirm
    confirm({
      title: `Delete page${currentPage}`,
      content:
        "Deleting this page will remove it from practice. Are you sure to delete this page?",
      okText: "Yes, delete this page",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        dispatch(setEditAddData({ type: "delete" }));
        dispatch(setPages(pages - 1));
        if (currentPage === 2) {
          dispatch(setCurrentPage(1));
          onBack();
        }
        dispatch(setCurrentPage(currentPage - 1));
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const onCompletePage = async () => {
    await form.validateFields();
    const values = form.getFieldsValue();
    dispatch(
      setEditAddData({
        type: "add",
        formData: Object.assign({}, values, { layout, options }),
      })
    );
    onComplete();
  };

  return (
    <Container>
      <div className="page-form">
        <div className="left-side">
          <div className="page-title">Edit Practice</div>
          <div className="nav-line flex-around">
            <Button
              icon={<CaretLeftOutlined />}
              type="text"
              disabled={currentPage < 2}
              onClick={onClickPrev}
            ></Button>
            <div className="text-center">{`Page${currentPage}`}</div>
            <Button
              icon={<CaretRightOutlined />}
              type="text"
              disabled={currentPage > pages - 1}
              onClick={onClickNext}
            ></Button>
          </div>
          <Select
            defaultValue={layout}
            value={layout}
            options={[
              {
                value: "desc",
                label: "Page Layout: Description",
              },
              {
                value: "short",
                label: "Page Layout: Short answer",
              },
              {
                value: "multi",
                label: "Page Layout: Mutiple choice",
              },
            ]}
            disabled={paramId}
            onChange={changeLayout}
          />
          <Form
            form={form}
            name="editAddForm"
            layout="vertical"
            size="large"
            className="full-width"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Title"
              name="title"
              rules={[{ required: true, message: "Please input page title" }]}
            >
              <TextArea
                showCount
                maxLength={100}
                placeholder="Input page title"
                style={{ resize: "none" }}
              />
            </Form.Item>
            {/* {layout !== "multi" && (
              <Form.Item label="Subtitle" name="subtitle">
                <TextArea
                  showCount
                  maxLength={100}
                  placeholder="Input page subtitle!"
                  style={{ resize: "none" }}
                />
              </Form.Item>
            )} */}
            <Form.Item label="Body content (Optional)" name="description">
              <TextArea
                showCount
                maxLength={100}
                placeholder="Input page body (Optional)"
                style={{ resize: "none" }}
              />
            </Form.Item>
            {layout === "multi" && (
              <Form.Item name="options">
                {!optionLoading && (
                  <div>
                    {/* <Checkbox onChange={onSingleSelect}>Single select</Checkbox> */}
                    {options &&
                      options.map((v, i) => (
                        <div className="flex-between" key={i}>
                          <Input
                            placeholder="Input option"
                            defaultValue={v}
                            className="mr-10 mt-10 mb-10"
                            data-index={i}
                            onChange={onInputOption}
                          />
                          <a
                            // disabled={singleSelect}
                            onClick={() => onDeleteOption(i)}
                          >
                            <CloseCircleFilled style={{ fontSize: "20px" }} />
                          </a>
                        </div>
                      ))}
                    <Button
                      // disabled={singleSelect}
                      onClick={onAddOption}
                    >
                      Add option
                    </Button>
                  </div>
                )}
              </Form.Item>
            )}
            <br />
            <Form.Item>
              <Row gutter={16}>
                <Col span={8}>
                  <Button
                    block
                    disabled={currentPage < 2}
                    onClick={onClickPrev}
                  >
                    Prev page
                  </Button>
                </Col>
                <Col span={8}>
                  <Button
                    block
                    disabled={currentPage > pages - 1}
                    onClick={onClickNext}
                  >
                    Next page
                  </Button>
                </Col>
                <Col span={8}>
                  <Button
                    type="primary"
                    block
                    key="submit"
                    htmlType="submit"
                    loading={loading}
                    disabled={currentPage < pages || paramId}
                  >
                    Add page
                  </Button>
                </Col>
              </Row>
              <Row gutter={16} className="mt-16">
                <Col span={12}>
                  <Button
                    block
                    danger
                    disabled={paramId}
                    onClick={onDeletePage}
                  >
                    Delete page
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    block
                    ghost
                    type="primary"
                    disabled={currentPage < pages}
                    onClick={onCompletePage}
                  >
                    Go publish
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </div>
        <div className="right-side">
          <MockPhone
            title={(summaryData && summaryData.name) || "Practice Name"}
          >
            <Progress
              className="mock-progress"
              percent={getPercent()}
              showInfo={false}
              strokeLinecap="square"
              strokeColor={"#547756"}
            />
            <div className="edit-add-title">{titleValue || "Title"}</div>
            {/* {layout !== "multi" && (
              <div className="edit-add-subtitle">
                {subtitleValue || "Subtitle (Optional)"}
              </div>
            )} */}
            {descriptionValue && (
              <pre className="edit-add-body">
                {descriptionValue || "Body content (Optional)"}
              </pre>
            )}
            {layout === "short" && (
              <div className="edit-add-textarea">
                <TextArea
                  style={{ height: 400, resize: "none", background: "#E9E5E1" }}
                />
              </div>
            )}
            {layout === "multi" && (
              <div className="mock-options">
                {/* {layout === "multi" && singleSelect && (
              <Checkbox>{options[0]}</Checkbox>
              )} */}
                {/* {layout === "multi" && !singleSelect && ( */}
                <Radio.Group>
                  <Space direction="vertical" size="large">
                    {options &&
                      options.map((v, i) => (
                        <Radio value={v} key={i}>
                          {v}
                        </Radio>
                      ))}
                  </Space>
                </Radio.Group>
                {/* )} */}
              </div>
            )}
            <Row gutter={16} className="edit-add-btns">
              <Col span={12}>
                <Button block size="large">
                  Back
                </Button>
              </Col>
              <Col span={12}>
                <Button block size="large" type="primary">
                  Next
                </Button>
              </Col>
            </Row>
          </MockPhone>
        </div>
      </div>
    </Container>
  );
}
