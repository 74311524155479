/* eslint-disable import/prefer-default-export */
import styled from "styled-components";

export const Container = styled.div`
  padding: 24px 32px;
  display: flex;
  justify-content: space-between;

  .profileBuilderLeftSide {
    flex-basis: 47%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 48px;
  }

  .profileTitle {
    font-family: "SF Pro Display";
    font-weight: 700;
    font-size: 34px;
    line-height: 40px;
    letter-spacing: 0.02em;
    color: ${({ theme }) => theme.primaryDark};
  }

  .mockupHintParagraph {
    font-family: "SF Pro Display";
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: ${({ theme }) => theme.primaryDark};
  }

  .profileBuilderRightSide {
    flex-basis: 47%;
    display: flex;
    justify-content: center;
  }

  .mock-top {
    text-align: center;
  }
  .mock-avatar {
    width: 85px;
    height: 85px;
    margin-top: 20px;
    border-radius: 85px;
  }
  .mock-headline {
    color: ${({ theme }) => theme.cGray};
    margin-top: 10px;
    margin-bottom: 30px;
  }
  .mock-item-title {
    font-weight: bold;
    border-top: 1px solid ${({ theme }) => theme.black15};
    padding: 30px 0 10px;
  }
  .mock-item-value {
    margin-bottom: 30px;
    font-size: 13px;
  }
`;
