/* eslint-disable import/prefer-default-export */
import styled from "styled-components";

export const Container = styled.div`
  .ant-tag {
    border-radius: 36px;
    line-height: 36px;
    height: 36px;
    padding-inline: 16px;
    font-size: 14px;
  }
  .ant-tag-close-icon {
    font-size: 14px;
  }
  .site-tag-plus {
    background: #fff;
    border-style: dashed;
  }
  .edit-tag {
    user-select: none;
  }
  .tag-select {
    width: 105px;
    height: 36px;
    margin-right: 8px;
    vertical-align: top;
  }
`;
