/* eslint-disable import/prefer-default-export */
import styled from "styled-components";

export const Container = styled.div`
  .btn-back {
    margin-top: 20px;
  }

  .page-title {
    margin-bottom: 10px;
  }

  .page-form {
    padding: 24px 32px;
    padding-top: 0;
    display: flex;
    justify-content: space-between;
  }

  .left-side {
    flex-basis: 47%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .right-side {
    flex-basis: 47%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .prompt-top {
    margin-bottom: 30px;
  }

  .prompt-top h3 {
    margin: 30px 0 20px;
  }
`;
