import { configureStore } from "@reduxjs/toolkit";
// import settingsReducer from '../features/settings/settingsSlice';
// import practiceReducer from './features/Practice/practiceSlice';
import practiceReducer from "./features/CreatePractice";
import userInfoReducer from "./features/UserInfo";

export default configureStore({
  reducer: {
    practice: practiceReducer,
    userInfo: userInfoReducer,
  },
});
