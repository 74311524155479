import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal, Tabs, Radio, Space } from "antd";
import { Container } from "./style";
import PracticeManager from "./PracticeManager";
import QuoteManager from "./QuoteManager";
import PromptManager from "./PromptManager";

export default function ContentManager() {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [radioValue, setRadioValue] = useState("practice");

  const tabs = [
    { key: "1", label: "Practices", children: <PracticeManager /> },
    { key: "2", label: "Quotes", children: <QuoteManager /> },
    { key: "3", label: "Prompts", children: <PromptManager /> },
  ];

  // Actions
  const onAdd = () => {
    setIsModalOpen(true);
  };

  const onOk = () => {
    switch (radioValue) {
      case "practice":
        navigate("/CreatePractice");
        break;
      case "quote":
        navigate("/CreateQuote");
        break;
      case "prompt":
        navigate("/CreatePrompt");
        break;
    }
  };

  const onCancel = () => {
    setIsModalOpen(false);
  };

  const onRadioChange = (e) => {
    setRadioValue(e.target.value);
  };
  return (
    <Container>
      <Modal
        title="Select a content type"
        open={isModalOpen}
        onOk={onOk}
        onCancel={onCancel}
      >
        <Radio.Group onChange={onRadioChange} value={radioValue}>
          <Space direction="vertical">
            <Radio value="practice">Practice</Radio>
            <Radio value="quote">Quote</Radio>
            <Radio value="prompt">Prompt</Radio>
          </Space>
        </Radio.Group>
      </Modal>
      <div className="flex-between">
        <div className="page-title">Content Manager</div>
        <Button size="large" type="primary" onClick={onAdd}>
          Create new content
        </Button>
      </div>
      <Tabs defaultActiveKey="1" items={tabs} className="content-tabs" />
    </Container>
  );
}
