import LoginPage from "../pages/LoginPage";
import ResetPwd from "../pages/ResetPwd";
import PageLayout from "../pages/PageLayout";
import ProfileBuilder from "../pages/main/ProfileBuilder";
import ProfileEditor from "../pages/main/ProfileEditor";
import ContentManager from "../pages/main/ContentManager/ContentManager";
import CreatePractice from "../pages/main/CreatePractice";
import CreateQuote from "../pages/main/CreateQuote";
import CreatePrompt from "../pages/main/CreatePrompt";
import Page404 from "../pages/Page404";

const requireAuth = (flag, elem) => {
  return flag ? elem : <LoginPage />;
};

const routes = (isLoggedIn) => [
  {
    path: "/Login",
    element: <LoginPage />,
  },
  {
    path: "/ResetPwd",
    element: <ResetPwd />,
  },
  {
    path: "/",
    element: requireAuth(isLoggedIn, <PageLayout />),
    children: [
      {
        index: true,
        element: <ContentManager />,
      },
      {
        index: true,
        path: "/ContentManager",
        element: <ContentManager />,
      },
      {
        path: "/CreatePractice",
        element: <CreatePractice />,
      },
      {
        path: "/CreateQuote",
        element: <CreateQuote />,
      },
      {
        path: "/CreatePrompt",
        element: <CreatePrompt />,
      },
      {
        path: "/ProfileBuilder",
        element: <ProfileBuilder />,
      },
      {
        path: "/ProfileBuilder/Edit",
        element: <ProfileEditor />,
      },
    ],
  },
  {
    path: "*",
    element: <Page404 />,
  },
];

export { routes };
