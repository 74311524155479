import React from "react";
import { Container } from "./style";
import { LeftOutlined } from "@ant-design/icons";
import StatusBar from "../../assets/app_status_bar.png";
import Tabbar from "../../assets/app_tabbar.png";

export default function MockPhone(props) {
  return (
    <Container>
      <div className="mock-phone">
        <img src={StatusBar} className="mock-status-bar" />
        {props.title && (
          <div className="mock-header">
            <div className="mock-back">
              <LeftOutlined />
            </div>
            <div className="mock-title">{props.title}</div>
          </div>
        )}
        <div className="mock-children">{props.children}</div>
        {props.showTabbar && <img src={Tabbar} className="mock-tabbar" />}
      </div>
    </Container>
  );
}
