import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container } from "./style";
import U from "../../assets/U.png";
import { Button, Form, Input, message } from "antd";
import { useDispatch } from "react-redux";
import { setLoginInfo, setUserInfo } from "../../store/features/UserInfo";
import { gql, useLazyQuery, useMutation } from "@apollo/client";

const GET_USER = gql`
  query findUser($id: String!) {
    user(_id: $id) {
      _id
      name
      profile_image
      role
      lastLoginAt
    }
  }
`;

export const GET_COACH = gql`
  query filterCoaches($filters: FilterCoachesInput) {
    filterCoaches(filters: $filters) {
      count
      limit
      offset
      coaches {
        _id
        name
        email
        belief
        about
        experience
        profile_image
        isFollowed
        expertise {
          _id
          backgroundUrl
          backgroundColor
          linearColor
          description
          title
        }
        createdAt
        updatedAt
      }
    }
  }
`;

const LOGIN_MUTATION = gql`
  mutation loginUser($input: LoginUserInput!) {
    login(loginUserInput: $input) {
      refreshToken
      accessToken
      idToken
      user {
        _id
        name
      }
    }
  }
`;

export default function LoginPage() {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [loginUser] = useMutation(LOGIN_MUTATION);
  const [getCoach] = useLazyQuery(GET_COACH);
  const [getUser] = useLazyQuery(GET_USER);

  const onFinish = async (values) => {
    console.log("Success:", values);
    setLoading(true);
    try {
      const res = await loginUser({ variables: { input: values } });
      dispatch(setLoginInfo({ ...res.data.login, email: values.email }));
      // Get user and coach data
      const userData = await getUser({
        variables: { id: res.data.login.user._id },
      });
      const coachData = await getCoach({
        variables: { filters: { userId: res.data.login.user._id } },
      });
      let coach = {};
      if (
        coachData.data.filterCoaches &&
        coachData.data.filterCoaches.coaches &&
        coachData.data.filterCoaches.coaches.length > 0
      ) {
        coach = Object.assign({}, coachData.data.filterCoaches.coaches[0], {
          expertise: coachData.data.filterCoaches.coaches[0].expertise.map(
            (v) => v._id
          ),
        });
      }
      const userInfo = { ...userData.data, coach };
      dispatch(setUserInfo(userInfo));
      if (userData.data.user && userData.data.user.lastLoginAt) {
        message.success("Login successfully");
        navigate("/");
      } else {
        // if user login first time, guide user to change init password
        navigate("/ResetPwd?type=change");
      }
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setLoading(false);
  };

  return (
    <Container>
      <img src={U} alt="U" className="bg-u" />
      <div className="loginPage">
        <div className="loginLogo">
          <div className="logo1">UVITA</div>
          <div className="logo2">PRO</div>
        </div>
        <div className="loginBox">
          <div className="loginBoxTitle">Sign In</div>
          <Form
            name="loginForm"
            layout="vertical"
            size="large"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input placeholder="Input your email" />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password placeholder="Input your password" />
            </Form.Item>
            <Link to={`/ResetPwd?type=forget`}>
              <div className="forget-pwd">Forget password</div>
            </Link>

            <Form.Item wrapperCol={{ span: 12 }}>
              <Button type="primary" block loading={loading} htmlType="submit">
                Sign In
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Container>
  );
}
