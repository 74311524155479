import React, { useEffect, useState } from "react";
import { Container } from "./style";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Form, Input, Modal, Tabs, Avatar, message } from "antd";
import { LeftOutlined, TagOutlined, EditOutlined } from "@ant-design/icons";
import ReactRouterPrompt from "react-router-prompt";
import MockPhone from "../../../components/MockPhone/index";
import UserAvatar from "../../../assets/User.png";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
const { TextArea } = Input;

const LIST_PROMPTS = gql`
  query ListPrompts($filters: ListPromptInput, $args: PaginationArgs) {
    listPrompts(filters: $filters, args: $args) {
      count
      limit
      offset
      prompts {
        _id
        content
        date
        authorName
        isBookmarked
        coach {
          _id
          name
          email
          belief
          about
          experience
        }
      }
    }
  }
`;
const CREATE_PROMPT = gql`
  mutation createPrompt($createPromptInput: CreatePromptInput!) {
    createPrompt(payload: $createPromptInput) {
      _id
    }
  }
`;
const UPDATE_PROMPT = gql`
  mutation updatePrompt($updatePromptInput: UpdatePromptInput!) {
    updatePrompt(payload: $updatePromptInput) {
      _id
    }
  }
`;

export default function CreatePrompt() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [rowData, setRowData] = useState({});
  const [createPrompt] = useMutation(CREATE_PROMPT);
  const [updatePrompt] = useMutation(UPDATE_PROMPT);
  const userInfo = useSelector((state) => state.userInfo.userInfo);
  const [params] = useSearchParams();
  const promptId = params && params.getAll("id") && params.getAll("id")[0];
  // Update prompt
  const [listPrompts, { data }] = useLazyQuery(LIST_PROMPTS, {
    variables: {
      filters: { _id: promptId },
    },
  });
  useEffect(() => {
    if (promptId) {
      listPrompts();
    }
  }, [promptId]);

  useEffect(() => {
    if (
      data &&
      data.listPrompts &&
      data.listPrompts.prompts &&
      data.listPrompts.prompts[0]
    ) {
      setRowData(data.listPrompts.prompts[0]);
    } else {
      setRowData({});
    }
  }, [data]);

  useEffect(() => {
    if (form && rowData._id) {
      form.setFieldsValue(rowData);
    }
  }, [rowData]);

  // Form
  const [form] = Form.useForm();
  const contentValue = Form.useWatch("content", form);

  useEffect(() => {
    if (contentValue && contentValue !== rowData.content) {
      setIsChanged(true);
    }
  }, [contentValue]);

  useEffect(() => {
    if (loading) {
      setIsChanged(false);
    }
  }, [loading]);

  const onFinish = async (values) => {
    console.log("Success:", values);
    setLoading(true);
    try {
      if (!promptId) {
        const param = {
          authorName: userInfo.user.name,
          coach: userInfo.coach._id,
          createdBy: userInfo.coach._id,
          date: new Date(),
          ...values,
        };
        await createPrompt({
          variables: {
            createPromptInput: param,
          },
        });
      } else {
        const param = {
          _id: promptId,
          createdBy: userInfo.coach._id,
          content: values.content,
        };
        await updatePrompt({
          variables: {
            updatePromptInput: param,
          },
        });
      }
      message.success("Prompt has been created!");
      navigate("/contentManager");
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setLoading(false);
  };

  const onDraft = () => {
    const values = form.getFieldsValue();
    console.log(values);
  };

  const onBack = () => {
    navigate("/contentManager");
  };

  return (
    <Container forceRender>
      <ReactRouterPrompt when={isChanged}>
        {({ isActive, onConfirm, onCancel }) =>
          isActive && (
            <Modal
              title="Unsaved changes"
              open
              footer={[
                <div key="flex-between" className="flex-between">
                  <Button
                    key="discard"
                    danger
                    loading={loading}
                    onClick={() => {
                      onConfirm();
                    }}
                  >
                    Discard Changes
                  </Button>
                  <div key="div">
                    <Button key="back" onClick={onCancel}>
                      Cancel
                    </Button>
                    <Button
                      key="save"
                      type="primary"
                      loading={loading}
                      onClick={() => {
                        onDraft();
                        onConfirm();
                      }}
                    >
                      Save Draft
                    </Button>
                  </div>
                </div>,
              ]}
            >
              <p>
                You are about to leave this page with unsaved changes. Would you
                like to save these changes before leaving?
              </p>
            </Modal>
          )
        }
      </ReactRouterPrompt>
      <div className="page-form">
        <div className="left-side">
          <Button
            className="btn-back"
            icon={<LeftOutlined />}
            type="text"
            onClick={onBack}
          >
            Content Manager
          </Button>

          <div className="page-title">
            {!promptId ? "Create a new Prompt" : "Update a Prompt"}
          </div>
          <Form
            form={form}
            name="promptForm"
            layout="vertical"
            size="large"
            className="full-width"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Enter your prompt"
              name="content"
              rules={[{ required: true, message: "Please input prompt" }]}
            >
              <TextArea
                showCount
                maxLength={150}
                placeholder="Empty"
                style={{ resize: "none", height: 150 }}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" block htmlType="submit" loading={loading}>
                Publish
              </Button>
              <Button block className="mt-16" onClick={onDraft}>
                Save as draft
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div className="right-side">
          <MockPhone showTabbar>
            <div className="prompt-top gray-all">
              <h3>Hello Hannah</h3>
              <Tabs
                defaultActiveKey="1"
                items={[
                  { key: "1", label: "For you" },
                  { key: "2", label: "Featured" },
                ]}
              ></Tabs>
            </div>
            <div className="flex-between mb-10">
              <div>Prompts</div>
              <Button type="text">
                <b>More</b>
              </Button>
            </div>
            <div className="info-box">
              <div className="flex-between mb-10">
                <div className="c-gray">By Coach Name</div>
                <TagOutlined />
              </div>
              <div className="info-content">{contentValue || "Empty"}</div>
              <div className="text-right">
                <Button type="primary" icon={<EditOutlined />}>
                  Write
                </Button>
              </div>
            </div>
            <div className="flex-between mb-10 gray-all">
              <div>Today's Quotes/Insights</div>
              <Button type="text">
                <b>More</b>
              </Button>
            </div>
            <div className="info-box gray-all">
              <div className="flex-between mb-10">
                <div>
                  <Avatar src={UserAvatar} className="mr-10" />
                  Coach Name
                  <span className="c-gray ml-10">FEB 24, 2023</span>
                </div>
                <TagOutlined />
              </div>
              <div className="info-content">
                "Is there a simple way to display a color bitmap in grayscale
                with just HTML/CSS? It doesn't need to be IE-compatible (and I
                imagine it won't be) -- if it works in FF3 and/or Sf3, that's
                good enough for me. I know I can do it with both SVG and Canvas,
                but that seems like a lot of work right now. Is there a truly
                lazy person's way to do this?"
              </div>
            </div>
            <div className="flex-between mb-10 gray-all">
              <div>Practices Recommended for you</div>
              <Button type="text">
                <b>More</b>
              </Button>
            </div>
          </MockPhone>
        </div>
      </div>
    </Container>
  );
}
