/* eslint-disable react/prop-types */
import React from "react";
import { Outlet } from "react-router-dom";
import Components from "../../components/index";
import { Container } from "./style";
import { message } from "antd";

export default function PageLayout() {
  const [, contextHolder] = message.useMessage();
  return (
    <Container>
      {contextHolder}
      <Components.Heading />
      <Components.Navigation />
      <Outlet />
    </Container>
  );
}
