/* eslint-disable import/prefer-default-export */
import styled from "styled-components";

export const Container = styled.div`
  padding: 24px 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  margin-left: -120px;

  .box404 {
    width: 500px;
    height: 300px;
    border: 3px solid ${({ theme }) => theme.primaryDefault};
    border-radius: 24px;
    text-align: center;
    background-color: #fff;
    color: ${({ theme }) => theme.primaryDefault};
    transform: rotate(30deg);
    position: relative;
    box-shadow: 10px 10px 5px 0px ${({ theme }) => theme.primary70};
  }
  .dot404 {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 16px;
    left: 16px;
    border-radius: 20px;
    background-color: ${({ theme }) => theme.primaryDefault};
  }
  .big-alert {
    margin-top: 30px;
    font-size: 84px;
  }
  .small-alert {
    font-size: 24px;
    margin-bottom: 30px;
  }
  .btn404 {
    margin: 0 auto;
  }
`;
